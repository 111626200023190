span.version-link {
  cursor: pointer;
  color: var(--vtmn-color_brand-digital);
}
.slides-modal {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.slides-modal .slide-carousel {
  min-width: 367px;
  width: 367px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.slides-modal .slide-carousel .carousel-indicators {
  margin-bottom: 0;
}
.slides-modal .slide-carousel .carousel-indicators li {
  background-color: var(--vtmn-color_grey-dark-1);
}
.slides-modal .slide-carousel .carousel-control-prev-icon,
.slides-modal .slide-carousel .carousel-control-next-icon {
  background-color: var(--vtmn-color_grey-dark-1);
  border-radius: 15px;
  padding: 15px;
  background-size: 20px;
}
.slides-modal .slide-carousel .carousel {
  height: 100%;
}
.slides-modal .slide-carousel .slide-image {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.slides-modal .slide-carousel .slide-preview-item {
  height: 750px;
  width: 350px;
  padding-bottom: 25px;
}
.slides-modal .slide-carousel .slide-content {
  height: 50%;
  display: flex;
  flex-direction: column;
}
.slides-modal .slide-carousel .slide-title {
  font-weight: 550;
  color: #021018;
  font-family: sans-serif;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slides-modal .slide-carousel .slide-description {
  color: var(--vtmn-color_grey);
  height: 100%;
  overflow-y: scroll;
}
.slides-modal .slide-carousel .slide-title,
.slides-modal .slide-carousel .slide-description {
  padding: 0 30px;
}
.slides-modal .modal-dialog {
  max-width: 397px;
}

.summary-fade-out {
  max-height: 170px;
  overflow: hidden;
  position: relative;
}
.summary-fade-out:after {
  content: '';
  width: 100%;
  height: 100%;    
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 50px, white);
}
.releases-page {
  margin-top: 16px;
}