.tooltip-inner {
  max-width: none;
}

.monitoringTooltipContent {
  padding: 0.25rem 0.5rem;
}

.monitoringTooltipContent .row {
  align-items: center;
}

.monitoringTooltipContent .row i {
  margin-right: 8px;
}

.monitoringTooltipContent i.danger {
  color: var(--vtmn-color_danger);
}

.monitoringTooltipContent i.warning {
  color: var(--vtmn-color_warning);
}

.monitoringTooltipContent i.success {
  color: var(--vtmn-color_success);
}
