body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-container {
  max-width: 100%;
  margin: var(--vp-core-spacing-0) var(--vp-core-spacing-250)
}

button:focus-visible {
  outline: 2px solid var(--vp-semantic-color-content-brand)
}

@media (min-width: 600px) and (max-width: 1200px) {
  .app-container {
      max-width:100%;
      margin: var(--vp-core-spacing-0) var(--vp-core-spacing-500)
  }
}

@media (min-width: 1200px) and (max-width: 1800px) {
  .app-container {
      max-width:100%;
      margin: var(--vp-core-spacing-0) var(--vp-core-spacing-650)
  }
}

@media (min-width: 1800px) {
  .app-container {
      max-width:1600px;
      margin: var(--vp-core-spacing-0) auto
  }
}