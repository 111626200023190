.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-button i {
  margin-right: 8px;
  display: inline-block;
}

.icon-button i.right {
  margin-right: 0px;
  margin-left: 8px;
  display: inline-block;
}

.icon-button i.subicon {
  font-size: 0.6rem;
  margin-right: 0;
  transform: translate(-13px, 4px);
  width: 0;
  overflow: visible;
}

.btn.tiny-button {
  padding: 0 .25rem;
  margin: 0 0.125rem;
  white-space: nowrap;
}

.tiny-button i {
  margin-top: 3px;
  margin-bottom: 3px;
}

.icon-button .buttonSpinner {
  margin-right: 8px;
  min-width: 1rem;
  min-height: 1rem;
}
