.monitoringGate > h3 {
  margin: 1rem;
}

.monitoringGate .monitoringGate-chartTitle {
  margin-top: 2rem;
}

.monitoringGate .monitoringGate-chart {
  height: 450px;
}

.gate-status-details .info-label,
.monitoringGate .info-label {
  color: var(--vtmn-color_grey);
  text-align: right;
  padding-right: 0;
}

.monitoringGate .info-tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.gate-status-details .info-value,
.monitoringGate .info-value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.gate-status-details .info-value {
  flex-grow: 2;
}

.monitoringGate .gate-statuses {
  margin-top: 1rem;
}

.monitoringGate .gate-statuses .info-value {
  flex-direction: column;
  align-items: flex-start;
}

.monitoringGate .info-value .status-info {
  cursor: default;
}

.monitoringGate .info-value .gate-status .btn {
  margin: 0 0.5rem 0 0;
}

.monitoringGate .info-value .gate-status .status-details i {
  margin-right: 0;
}

.gate-status-details .btn {
  margin: 0;
}

.gate-status-details .modal-body {
  max-height: 70vh;
  overflow: auto;
}

.gate-status-details .modal-body pre {
  border: 1px solid var(--vtmn-color_grey-light-2);
  padding: 0.5rem;
  width: 100%;
}

.monitoringGate .progress-value {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}

.monitoringGate .progress-value .progress {
  max-width: 480px;
  margin-bottom: 0;
  flex: 1 1 auto;
  position: relative;
}


.monitoringGate .progress-percent {
  position: absolute;
  left: 48%;
}

.monitoringGate .progress-percent-dark {
  color: var(--vtmn-color_black) !important;
}

.monitoringGate .color-success {
  color: var(--vtmn-color_success);
}

.monitoringGate .color-warning {
  color: var(--vtmn-color_warning);
}

.monitoringGate .color-danger {
  color: var(--vtmn-color_danger);
}

.monitoringGate .filter-item {
  width: 300px;
  margin-left: 0;
}

.monitoringGate .filter-item .react-datepicker-wrapper,
.monitoringGate .filter-item .react-datepicker__input-container,
.monitoringGate .filter-item .react-datepicker__input-container input {
  width: 150px;
}

@media (max-width: 991px) {
  .monitoringGate .info-label,
  .monitoringGate .info-value {
    font-size: 12px;
  }
}
