.edit-file-upload-button {
  margin: 0;
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}

.edit-file-upload-button.edit-file-upload-button-over {
  outline: 4px var(--vtmn-color_grey-light-1) dashed;
}

.edit-file-upload-button i {
  font-size: 64px;;
}

.edit-file-wrapper {
  margin: 0;
  height: 120px;
  width: 120px;
  border-radius: 5px;
  background-color: var(--vtmn-color_grey-light-3);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: left;
  overflow: hidden;
}

.edit-file-result-photo {
  height: 120px;
  width: 120px;
  background-color: #000;
  border-radius: 5px;
  cursor: default;
  object-fit: contain;
}

.edit-file-wrapper .edit-file-delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  margin: 0;
  cursor: pointer;
}

.edit-file-wrapper .edit-file-edit-button {
  position: absolute;
  top: 5px;
  right: 40px;
  padding: 5px;
  margin: 0;
  cursor: pointer;
}

.edit-file-wrapper .edit-file-edit-button input {
  display: none;
}

.edit-file-wrapper .edit-file-edit-button::before {
  margin-right: 0;
}

.edit-file-wrapper .edit-file-download-button,
.edit-file-wrapper .edit-file-preview-button {
  position: absolute;
  top: 5px;
  right: 75px;
  padding: 5px;
  margin: 0;
  cursor: pointer;
}

.edit-file-preview-modal .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-file-preview-modal .modal-footer {
  padding: 0;
}

.edit-file-preview-modal .spacer {
  flex: 1 1 auto;
}

.edit-file-error {
  display: block;
}

.edit-file-file-icon {
  color: --primary !important;
  font-size: 60px;
  position: absolute;
  bottom: 8px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-decoration: none !important;
}

.edit-file-file-extension {
  font-size: 14px;
}
