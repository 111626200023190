@import '@vtmn/css/dist/index.css';
@import "@vtmn/icons/dist/vitamix/font/vitamix.css";
@import '@fontsource/roboto';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/400-italic.css';

:root {
  --vtmn-semantic-color_background-secondary-new: #e1e0df;
  --vtmn-play-color_background-brand-secondary: #ecedf8;
  --vtmn-play-color_background-secondary: #f5f4f5;
  --vtmn-play-color_background-hover-secondary-transparent: #1010100D;
  --vtmn-play-color_background-brand-primary: #3643BA;
}

.hover\:vtmn-typo_text-3:hover {
  color:var(--vtmn-semantic-color_content-primary);
  text-decoration: none;
}
.vtmn-active-box-shadow-border-active {
  box-shadow: inset 0 0 0 0.075rem var(--vtmn-semantic-color_border-active);
}
.nowrap {
  white-space: nowrap;
}
.vp-button--small {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.multiselect-value-chip {
  height: 24px !important;
  padding-right: 0px !important;
  padding-left: 8px !important;
}
.multiselect-value-chip-close {
  width: 24px !important;
  height: 24px !important;
}
.vtmn-table-border-gray {
  border-color: var(--vtmn-semantic-color_background-secondary-new);
}
.vtmn-z-index-1 {
  z-index: 1 !important;
}
.drawer {
  z-index: 2000 !important;
}
.vtmn-filter-min-width {
  min-width: 236px;
}
.vtmn-filter-max-width {
  max-width: 270px;
}
.vtmn-new-bg-background-brand-secondary {
  background-color: var(--vtmn-play-color_background-brand-secondary);
}
.hover\:vtmn-new-bg-background-brand-secondary:hover {
  background-color: var(--vtmn-play-color_background-brand-secondary);
}
.vtmn-new-bg-background-secondary {
  background-color: var(--vtmn-play-color_background-secondary);
}
.vtmn-new-bg-hover-secondary-transparent {
  background-color: var(--vtmn-play-color_background-hover-secondary-transparent);
}
.hover\:vtmn-new-bg-hover-secondary-transparent:hover {
  background-color: var(--vtmn-play-color_background-hover-secondary-transparent);
}
.vtmn-new-bg-background-brand-primary {
  background-color: var(--vtmn-play-color_background-brand-primary);
}
a.vp-body-m {
  text-decoration: none !important;
  color: #212529 !important;
}