.error-message {
  border: 2px solid var(--vtmn-color_danger);
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.error-message .error-message-icon {
  color: var(--vtmn-color_danger);
  margin-right: 1rem;
  font-size: 24px;
}

.error-message .error-message-text {
  flex: 1 1 auto;
  word-wrap: anywhere;
}
