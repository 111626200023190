@import 'stylesheets/form.css';
@import 'stylesheets/inlineButton.css';

.with-navbar{
  width: 100%;
  overflow-y: auto;
  padding-top: 48px;
  /* padding-left: 16.666667%; */
}

ul{
  padding-left: 0px;
}

h5{
  font-size:0.9rem;
}

p{
  margin-bottom: 0.5rem;
}

.nav-item{
  color: var(--gray-dark);
}

.nav-item:hover{
  text-decoration: none;
}

#photo_avatar{
  width : 40px;
}

.givenName{
  text-transform:lowercase;
}
.givenName::first-letter{
  text-transform:uppercase;
}

.fa-power-off{
  margin:10px;
}

.loginButton{
  width:150px;
}

.clickableText:hover{
  cursor: pointer;
  color: var(--primary);
}

.list-group-item{
  text-transform: lowercase;
  padding: 0.75rem 0.75rem;
}

/* .itemDescription{
  font-size: 0.80rem;
  padding-top: 30px;
} */

/* .itemImage{
  @include media-breakpoint-up(lg){
    width: 80px;
  }
  @include media-breakpoint-down(md){
    width: 50px;
  }

  padding-right: 0px;
  padding-left: 0px;
  margin: auto;
} */

.btn{
  margin: 15px;
}

.textRightAligned{
  text-align: right;
}

.detailedResults{
  border-width: thin;
  border-color: gray;
}

.delivery{
  margin-left:2%;
  color:white;
  padding:3px;
}

.parcel{
  background-color: blue;
  width:93%;
  border-bottom-right-radius: 5px;
  font-size: 0.8rem;
}

.product{
  background-color: gray;
  width:91%;
  border-bottom-right-radius: 5px;
  border-color: white;
  border-bottom-style: solid;
  border-bottom-width:thin;
  font-size: 0.8rem;
}

.logo{
  width: 80%;
  max-width: 210px;
  margin-bottom: 10px;
}

.productFlow{
  width:90%;
  max-width:400px;
}

.back{
  background-image: url("./images/back.png");
}

.list-header{
  background-color: #F8F9F9;
  padding-top: 7px;
}

.progress-bar-success {
  background-color: var(--vtmn-color_success);
}

.progress-bar-warning {
  background-color: var(--vtmn-color_warning);
}

.progress-bar-danger {
  background-color: var(--vtmn-color_danger);
}

.inline-error {
  font-size: 12px;
  color: var(--vtmn-color_danger);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-card:hover{
  cursor: pointer;
}

.country-flag {
  width: 32px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--vtmn-color_grey-light-2);
}

.end2end-chartjs-tooltip {
  background-color: rgba(0,0,0,0.9);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.end2end-chartjs-tooltip table tr td {
  padding-left: 18px;
  padding-right: 15px;
}
.end2end-chartjs-tooltip .sublabel {
  font-weight: 800;
}
.end2end-chartjs-tooltip .blue-text {
  color: var(--vtmn-color_brand-digital-light-1);
}
.end2end-chartjs-tooltip .green-text {
  color: var(--vtmn-color_green-light-1);
}
.end2end-chartjs-tooltip .red-text {
  color: var(--vtmn-color_danger);
}
.end2end-chartjs-tooltip .margin-top {
  padding-top: 20px;
}
.end2end-chartjs-tooltip .color-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
}
.end2end-chartjs-tooltip .chart-color {
  border: #fff solid 1px;
  border-radius: 0.1rem;
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.mobile-markdown {
  color: #6e7781 !important;
  font-size: 14px !important;
  font-family: sans-serif !important;
  background-color: inherit !important;
}
.mobile-markdown h3 {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #6e7781 !important;
  margin: 0 !important;
}
.mobile-markdown ul {
  margin-bottom: 5px !important;
  padding: 0 !important;
}
.mobile-markdown ul li {
  margin-left: 21px !important;
  padding-left: 2px !important;
}
.mobile-markdown code {
  font-family: monospace !important;
  background-color: #f5f5f5 !important;
  color: #6e7781 !important;
}
.mobile-markdown table {
  display: table !important;
  width: 100% !important;
  table-layout: fixed !important;
  overflow-wrap: break-word !important;
}
.slides-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.slides-wrapper .slide-carousel {
  min-width: 367px;
  width: 367px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.slides-wrapper .slide-carousel .carousel-indicators {
  margin-bottom: 0;
}
.slides-wrapper .slide-carousel .carousel-indicators li {
  background-color: var(--vtmn-color_grey-dark-1);
}
.slides-wrapper .slide-carousel .carousel-control-prev-icon,
.slides-wrapper .slide-carousel .carousel-control-next-icon {
  background-color: var(--vtmn-color_grey-dark-1);
  border-radius: 15px;
  padding: 15px;
  background-size: 20px;
}
.slides-wrapper .slide-carousel .carousel {
  height: 100%;
}
.slides-wrapper .slide-carousel .slide-image {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.slides-wrapper .slide-carousel .slide-preview-item {
  height: 750px;
  width: 350px;
  padding-bottom: 25px;
}
.slides-wrapper .slide-carousel .slide-content {
  height: 50%;
  display: flex;
  flex-direction: column;
}
.slides-wrapper .slide-carousel .slide-title {
  font-weight: 550;
  color: #021018;
  font-family: sans-serif;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slides-wrapper .slide-carousel .slide-description {
  color: var(--vtmn-color_grey);
  height: 100%;
  overflow-y: scroll;
}
.slides-wrapper .slide-carousel .slide-title,
.slides-wrapper .slide-carousel .slide-description {
  padding: 0 30px;
}
