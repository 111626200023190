.form-checkbox-row {
  padding-left: 15px;
  padding-right: 15px;
}

.form-datepicker-col {
  display: flex;
}

.form-datepicker-col .react-datepicker-wrapper {
  flex: 1 1 auto;
}

.form-file-col {
  display: flex;
  flex-direction: column;
}

.react-datepicker__close-icon::after {
  font-size: 1rem !important;
  background-color: transparent !important;
  color: #000 !important;
  opacity: 0.5;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.react-datepicker__close-icon:hover::after {
  opacity: 0.75;
}

.form-item input.form-control {
  width: 232px;
}

/* date+time small screen fix */
.datetime-edit .react-datepicker {
  min-width: 325px;
}

.datetime-edit .react-datepicker__month-container {
  width: 240px;
}

.datetime-edit .react-datepicker__time-container {
  width: 85px;
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
}

.invisible-file-input {
  display: none;
}

.filter-item-checkbox {
  margin-top: 24px;
  display: inline-flex;
  align-items: center;
}