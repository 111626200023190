.liveStoreReception .table {
  position: relative;
}

.liveStoreReception .tableHeader {
  font-size: 0.9rem;
  font-weight: normal;
  line-height: 1;
}

.liveStoreReception .tableHeader th {
  vertical-align: middle;
  padding: 0.25rem 0.5rem;
  height: 2.5rem;
}

.tabHeader {
  font-size: 0.9rem;
  font-weight: normal;
}

.liveStoreReception .tabHeader {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.disclaimer{
  font-size: 0.9rem;
  font-style: italic;
  font-weight: lighter;
}

.card-text{
  font-size: 0.9rem;
  margin-bottom: 3px;
}

.progress-clickable {
  cursor: pointer;
}

.progress-bar-begin{
  color:black;
}

.deliveries{
  font-size: 0.9rem;
}

.total{
  margin-bottom: 15px;
}

.row{
  margin-left: 0px;
  margin-right: 0px;
}

.list-group-item{
  padding:3px;
}

.card-title{
  padding-left: 0px;
}

.vtmn-icon_edit_plus{
  margin-bottom: 3px;
}

.vtmn-icon_edit_minus{
  margin-bottom: 3px;
}

.liveStoreReception .vtmn-progress .progress-bar:first-of-type {
  overflow: visible;
  z-index: 10;
}

.liveStoreReception .vtmn-progress .progress-bar:not(:first-of-type) {
  min-width: 0.5%;
}

.liveStoreReception .chevronHeader{
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  padding: 0.5rem;
  border-bottom: none;
}

.liveStoreReception .refreshHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.liveStoreReception .refreshHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vtmn-icon_arrow2_down{
  line-height: unset;
}

.liveStoreReception td .iconCell {
  display: flex;
  justify-content: center;
}

.liveStoreReception td .iconCell .robot-icon {
  height: 24px;
  margin: -2px 2px -2px 2px;
}

.liveStoreReception .deliverySpinner {
  margin-right: 8px;
  min-width: 1rem;
  min-height: 1rem;
  align-self: center;
}

.liveStoreReception .filter-button {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.liveStoreReception .mobile-status .mobile-status-wrapper {
  display: flex;
  justify-content: space-between;
}

.liveStoreReception .mobile-status .iconCell {
  display: inline-flex;
}

.liveStoreReception .mobile-tags,
.liveStoreReception .mobile-change-date {
  font-size: 0.6rem;
  opacity: 0.7;
}

.liveStoreReception .dataTableFooter {
  margin-bottom: 0;
}

.minimumPriceFilter {
  margin-top: 24px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}

.minimumPriceFilter span {
  white-space: nowrap;
}

.minimumPriceFilter .filter-item {
  width: 100px;
}

.minimumPriceFilter .filter-item-switch {
  width: 28px;
}

.minimumPriceFilter .filter-item input {
  width: 100%;
}

.minimumPriceFilter .filter-item-switch {
  margin-top: 0px;
}

.liveStoreReception .shipmentsTableFooter {
  margin-bottom: 16px;
}

.liveStoreReception .vtmn-btn.refreshButton {
  padding: 0 5px;
  height: 30px;
  min-width: auto;
}

.liveStoreReception .vtmn-btn.refreshButton:focus {
  box-shadow: none;
}

.liveStoreReception .loaderWrapper {
  display: flex;
  justify-content: center;
}

.unexpected-summary-content .card-title {
  padding: 25px 16px 0 16px;
}

.progress-bar-info {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 7px;
}